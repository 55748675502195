import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
//import parse from "html-react-parser"
import Logo from "../../static/logosvg.svg"
import { Helmet } from "react-helmet"
import CookieConsent from "react-cookie-consent"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "/src/components/fontawsome.js"

import Contact from "../components/sections/contact"
import MainMenu from "../components/menu"

const Layout = ({ isHomePage, children }) => {
  const { profile } = useStaticQuery(graphql`
    query LayoutQuery {
      profile: wpPage(isFrontPage: { eq: true }) {
        seo {
          schema {
            raw
          }
        }
      }
    }
  `)
  const [open, setOpen] = React.useState(false)

  return (
    <div className="global-wrapper" data-is-root-path={isHomePage}>
      <Helmet>
        <script type="application/ld+json">{profile.seo.schema.raw}</script>
        <meta name="monetization" content="$ilp.uphold.com/fPwN9Yq7nejr" />
        <meta name="geo.placename" content="Esher" />
        <meta name="geo.position" content="51.369030;-0.366320" />
        <meta name="geo.region" content="United Kingdom (UK)" />
      </Helmet>
      <header className="global-header">
        <Link to="/" aria-label="Home" className="logo-branding">
          <img
            src={Logo}
            className="logo"
            width="75"
            alt="Direct Design Studio Limited"
          />
        </Link>

        <div className={open ? "main-menu open" : "main-menu"}>
          <button
            onClick={() => {
              setOpen(!open)
            }}
            className={open ? "menu-toggle open" : "menu-toggle"}
            aria-label="Menu"
          >
            <FontAwesomeIcon
              icon={["fas", "ellipsis-v"]}
              className="open-menu"
            />
            <FontAwesomeIcon icon={["fas", "times"]} className="close-menu" />
          </button>
          <MainMenu />
        </div>
      </header>

      <main>{children}</main>

      <footer className="site-footer">
        <Contact />
      </footer>
      <CookieConsent
        location="bottom"
        buttonText="Sure man!!"
        cookieName="myAwesomeCookieName"
        style={{ background: "#1e2b31", color: "#ffcd0d", zIndex: 99999999 }}
        buttonStyle={{
          background: "#ffcd0d",
          color: "#1e2b31",
          fontSize: "15px",
          padding: "20px",
        }}
      >
        This website uses cookies to ensure you get the best experience on our
        website.{" "}
        <a
          href="https://www.cookiesandyou.com/"
          target="_blank"
          rel="noreferrer"
          aria-label="learn more about cookies"
          style={{ color: "#ffffff" }}
        >
          Learn More
        </a>
      </CookieConsent>
    </div>
  )
}

export default Layout
