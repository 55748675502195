import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const MainMenu = toggleOpen => {
  const menu = useStaticQuery(graphql`
    {
      wpMenu(name: { eq: "Main Menu" }) {
        id
        menuItems {
          nodes {
            label
            url
            id
          }
        }
      }
    }
  `)

  return (
    <nav id="navigtaion" className="menu">
      <ul className="menu-ul">
        {menu.wpMenu.menuItems.nodes.map(node => {
          if (node.label === "Home") {
            return (
              <li className="nav-item" key={node.id}>
                <Link className="nav-link" to={node.url}>
                  {node.label}
                </Link>
              </li>
            )
          } else {
            return (
              <li className="nav-item" key={node.id}>
                <Link className="nav-link" to={"/" + node.url}>
                  {node.label}
                </Link>
              </li>
            )
          }
        })}
      </ul>
    </nav>
  )
}

export default MainMenu
