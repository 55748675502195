import React, { useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "/src/components/fontawsome"
import Logo from "/static/logosvg.svg"

const Contact = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: wp {
        themeGeneralSettings {
          ThemeSettings {
            address
            contactDetails
            twitterUrl
            linkedinUrl
            directors
          }
        }
      }
    }
  `)

  useEffect(() => {
    window.$crisp = []
    window.CRISP_WEBSITE_ID = "bf08fa62-e1be-4653-8ed0-4a41457e8081"
    ;(function () {
      var d = document
      var s = d.createElement("script")

      s.src = "https://client.crisp.chat/l.js"
      s.async = 1
      d.getElementsByTagName("head")[0].appendChild(s)
    })()
    window.$crisp.push(["safe", true])
  }, [])

  return (
    <div>
      <div id="contact" className="container contactUs">
        <div className="row">
          <div className="col-12">
            <h2>Contact Us</h2>
          </div>
        </div>
        <div className="row company-details">
          <div className="col-12 col-md-6">
            <div className="contact_details_field">
              {parse(data.themeGeneralSettings.ThemeSettings.contactDetails)}
            </div>
          </div>
          <div className="col-12 col-md-6">
            {parse(data.themeGeneralSettings.ThemeSettings.directors)}
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#ffffff" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2490.864117046356!2d-0.36793348423510025!3d51.36879467961256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760ac36dc95035%3A0xb4d6d5904d7b7595!2sDirect%20Design!5e0!3m2!1sen!2suk!4v1616759546541!5m2!1sen!2suk"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="Direct Design Esher"
        ></iframe>
      </div>
      <div id="bottom-of-footer" className="bottom-of-footer">
        <Link to="/" aria-label="Home">
          <img
            src={Logo}
            className="logo"
            width="75"
            alt="Luke O'Regan - Developer/Designer"
            style={{ marginBottom: "20px" }}
          />
        </Link>
        {parse(data.themeGeneralSettings.ThemeSettings.address)}
        <Link
          to="/privacy-policy"
          aria-label="Privacy Policy"
          style={{
            fontSize: "10pt",
            fontWeight: "bold",
            marginBottom: "20px",
            display: "block",
          }}
        >
          View our Privacy Policy{" "}
        </Link>
        <div className="social">
          <a
            href="https://twitter.com/designbydda"
            target="_blank"
            rel="noreferrer"
            aria-label="Visit our Twitter page"
          >
            <FontAwesomeIcon icon={["fab", "twitter"]} />
          </a>
          <a
            href="https://www.linkedin.com/company/direct-design-studio/about/"
            target="_blank"
            rel="noreferrer"
            aria-label="Visit our LinkedIn company profile"
          >
            <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
          </a>
        </div>
        <p style={{ fontSize: "10px" }}>
          <br />© Direct Design Studio Limited 1989 - {new Date().getFullYear()}
          , <br />
          Built with
          {` `}
          <a href="https://www.gatsbyjs.com" target="_blank" rel="noreferrer">
            Gatsby
          </a>
          {` `}
          And{" "}
          <a href="https://wordpress.org/" target="_blank" rel="noreferrer">
            WordPress
          </a>
        </p>
      </div>
    </div>
  )
}

export default Contact
